import React from 'react'
import classNames from 'classnames'
import ReactPlayer from 'react-player'

const AboutMediaEmbed = ({ styles, url }) => {
  let aboutMediaStyles; let
    reactPlayerStyles
  if (!url) {
    aboutMediaStyles = { display: 'none' }
  } else {
    aboutMediaStyles = {
      position: 'relative',
      paddingTop: '56.25%',
    }
    reactPlayerStyles = {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '96%',
      height: '100%',
      margin: '20px 2%',
    }
  }

  return (
    <div style={aboutMediaStyles}>
      <ReactPlayer width='96%' height='100%' style={reactPlayerStyles} url={url} />
    </div>
  )
}

export default AboutMediaEmbed
