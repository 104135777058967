export const selector = (state = {}, action) => {
  let modifiedPrice
  switch (action.type) {
    case 'SELECT_YEARLY':
      modifiedPrice = (state.price.slice(1, state.price.length))
      return {
        ...state,
        yearlySelected: true,
        currentPrice: state.initialPeriod === 'yearly' ? state.price : `$${(modifiedPrice.replace(',', '') * 10).toFixed(2)}`,
      }
    case 'SELECT_MONTHLY':
      modifiedPrice = (state.price.slice(1, state.price.length))
      return {
        ...state,
        yearlySelected: false,
        currentPrice: state.initialPeriod === 'monthly' ? state.price : `$${(modifiedPrice.replace(',', '') / 10).toFixed(2)}`,
      }
    default:
      return state
  }
}

export const openModal = (state = {}, action) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        modalType: action.modalType,
        modalProps: action.modalProps,
      }
    case 'HIDE_MODAL':
      return {
        modalType: null,
        modalProps: {},
      }
    case 'RECEIVE_SUBSCRIPTION':
      return {
        modalType: 'success',
      }
    case 'RECEIVE_SUBSCRIPTION_SCA':
      return {

        modalType: 'success',
        user: action.results.user,
        subscription: action.results.subscription,
      }
    case 'ADDITIONAL_ERROR':
      return {
        modalType: 'error',
      }
    default:
      return state
  }
}

const initialFormState = {
  errors: [],
  subscribing: false,

}

export const form = (state = initialFormState, action) => {
  //  const form = { ...state }
  switch (action.type) {
    case 'INVALIDATE_FORM':
      form.errors = action.errors
      return form
    case 'ADDITIONAL_ERROR':
      form.subscribing = false
      form.errors = [action.error]
      return form
    case 'REQUEST_SUBSCRIPTION':
      form.subscribing = true
      return form
    case 'RECEIVE_SUBSCRIPTION':
      form.subscribing = false
      form.errors = []
      return form
    case 'RECEIVE_SUBSCRIPTION_SCA':
      form.subscribing = false
      form.errors = []
      form.user = action.user
      form.subscription = action.subscription
    default:
      return state
  }
}

export const page = (state = {}, action) => {
  switch (action.type) {
    case 'SELECT_YEARLY':
      return {
        ...state,
        period: 'yearly',
        price: state.price * 10,
      }
    case 'SELECT_MONTHLY':
      return {
        ...state,
        period: 'monthly',
        price: state.price / 10,
      }
    default:
      return state
  }
}

export const stripe = (state = {}, action) => {
  switch (action.type) {
    case 'SET_STRIPE': {
      return action.stripe
    }
    default:
      return state
  }
}
export const coupon = (state = {}, action) => {
  switch (action.type) {
    case 'TOGGLE_COUPON_FIELD':
      return {
        ...state,
        couponFieldVisible: action.visible,
      }
    case 'COUPON_VALID':
      return {
        ...state,
        couponSubmitted: true,
        ...action.coupon,
      }
    case 'COUPON_INVALID':
      return {
        ...state,
        couponSubmitted: true,
      }
    case 'COUPON_VALIDATING':
      return {
        ...state,
        couponValidating: action.status,
      }
    default:
      return state
  }
}

export const params = (state = {}, action) => {
  switch (action.type) {
    default:
      return state
  }
}
