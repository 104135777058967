import React from 'react'
import lifecycle from 'react-pure-lifecycle'

import {
  validateAndPostSubscription,
  validateFormOnly,
  toggleCouponField,
  validateCoupon,
  additionalError
} from '../client/subscribe/actions'

import WelcomeModal from './WelcomeModal'
import ErrorModal from './ErrorModal'
import Spinner from '../clientAdmin/components/Spinner'

const makePeriodSwitchHandler = (period) => (event) => {
  event.preventDefault()
  store.dispatch({ type: period })
  return false
}

const displayCouponField = () => {
  store.dispatch(toggleCouponField(true))
}

// NOTE: In future used controlled compoenent, DOM access is antipattern
const handleCouponClick = (event) => {
  event.preventDefault()
  const coupon = document.getElementById('couponField').value
  store.dispatch(validateCoupon(coupon))
}

const handleKeyPress = (event) => {
  if (event.key == 'Enter') {
    const coupon = event.target.value
    store.dispatch(validateCoupon(coupon))
    event.preventDefault()
  }

  return false
}

const formatDiscount = (
  couponValid,
  couponAmountOff,
  couponPercentOff,
  couponDurationInMonths,
  couponDuration,
  couponCurrency
) => {
  const couponCurrencyUC = couponCurrency.toUpperCase()
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currencyDisplay: 'symbol',
    currency: couponCurrencyUC,
    minimumFractionDigits: 2,
  })

  const quantity = couponAmountOff
    ? `${formatter.format(couponAmountOff / 100)}  off `
    : `${couponPercentOff}% off `

  const duration = (couponDuration === 'forever' || couponDuration === 'once')
    ? couponDuration
    : `for ${couponDurationInMonths} months`

  if (couponValid) {
    return quantity + duration
  }

  return 'automatic recurring payments'
}


const validateFormValue = (field) => (event) => {
  if (!event.target.form) {
    return;
  }
 
  
  store.dispatch(validateFormOnly({
    email: event.target.form.email.value,
    cardNumber: event.target.form.cardNumber.value,
    date: event.target.form.date.value,
    cvc: event.target.form.cvc.value,
    affiliate: null,
  }, field))

}
const submitForm = (field) => (event) => {
  event.preventDefault()

  const affiliate = document.URL.split('?id=')[1]

  if (typeof clicky !== 'undefined') {
    clicky.goal('paidInviteSignup')
  }

  if(window.lp_event) window.lp_event('initiate_checkout')
  

  
  const form = event.target.form

  if (!form) {
    store.dispatch(additionalError({ status: 'error', message: 'Please contact support@launchpass.com to complete your purchase or try a different browser.  JSERROR' }))
    return;
  }

  const validateForm = field ? validateFormOnly : validateAndPostSubscription

  store.dispatch(validateForm({
    email: form.email.value,
    cardNumber: form.cardNumber.value,
    date: form.date.value,
    cvc: form.cvc.value,
    affiliate,
  }, field))

  return false
}

const componentDidMount = (props) => {
  if (props.coupon) {
    store.dispatch(toggleCouponField(true))
    store.dispatch(validateCoupon(props.coupon))
  }
}

const methods = {
  componentDidMount,
}

const StripeForm = ({
  styles,
  url,
  price,
  priceYearly,
  yearlySelected,
  currentPrice,
  currency='usd',
  errors,
  period,
  trialPeriod,
  openModal,
  modalStyles,
  channel,
  group,
  subscribing,
  couponValidating,
  buttonColor,
  textColor,
  backgroundColor,
  status,
  alternatePayment,
  convertCode,
  couponsEnabled,
  couponFieldVisible,
  couponValid,
  couponId,
  couponAmountOff,
  couponPercentOff,
  couponDurationInMonths,
  couponDuration,
  couponSubmitted,
  couponCurrency = 'usd',
  email,
  coupon,
}) => {
  if (couponCurrency === null || couponCurrency === undefined) {
    couponCurrency = 'usd'
  }
  if (currency === null || currency === undefined) {
    currency = 'usd'
  }
  const selected = [styles.planSelectButton, styles.buttonActive]
  const unselected = [styles.planSelectButton, styles.buttonInactive]
  const inputStyles = status === 'Disabled' ? styles.disabledFormInput : styles.formInput
  const payBtnStyles = status === 'Disabled' ? styles.disabledPayBtn : styles.payBtn

  const couponInfo = formatDiscount(
    couponValid,
    couponAmountOff,
    couponPercentOff,
    couponDurationInMonths,
    couponDuration,
    couponCurrency
  )

  const monthlyStyles = {
    borderColor: buttonColor || '#469CCE',
    color: '#FFF',
    backgroundColor: period === 'monthly' ? buttonColor || '#469CCE' : backgroundColor || '#4D4C69',
  }

  const yearlyStyles = {
    borderColor: buttonColor || '#469CCE',
    color: '#FFF',
    backgroundColor: period === 'yearly' ? buttonColor || '#469CCE' : backgroundColor || '#4D4C69',
  }

  trialPeriod = parseInt(trialPeriod, 10)

  const currencyUC = currency.toUpperCase()

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currencyDisplay: 'symbol',
    currency: currencyUC,
    minimumFractionDigits: 2,
  })

  // Format purchase button text
  const priceNum = Number(currentPrice.replace(/[^0-9.]+/g, ''))
  let formattedPrice = formatter.format(priceNum)

  if(couponValid && couponSubmitted){
    let priceAfterCoupon = couponAmountOff
      ? priceNum - (couponAmountOff / 100)
      : priceNum - (priceNum * (couponPercentOff / 100))

    if (priceAfterCoupon < 0) priceAfterCoupon = 0

    formattedPrice = formatter.format(priceAfterCoupon)
  }

  let btnContent = ''
  if(trialPeriod){
    btnContent = 'Start Your Trial'
  } else {
    btnContent = `Pay ${formattedPrice} ${currencyUC}`
  }

  errors = errors || []

  const formErrors = errors.reduce((acc, error) => {
    acc[error.field] = styles.fieldError
    return acc
  }, {})
  return (
    <div>
      { (openModal && (openModal.modalType === 'success'))
        && <WelcomeModal
          styles={modalStyles}
          group={group}
          channel={channel}
        />
      }
      { (openModal && (openModal.modalType === 'error'))
        && <ErrorModal
          styles={modalStyles}
          group={group}
          channel={channel}
          error={errors}
        />
      }
      { (trialPeriod > 0)
        && <div className={styles.trialDetail} style={{ color: textColor || '#fff' }}>
          {`${trialPeriod} day free trial`}
        </div>
      }
      {alternatePayment
        && <p className={styles.paymentSelectorGroup}><span style={monthlyStyles} className={styles.paymentSelector} onClick={makePeriodSwitchHandler('SELECT_MONTHLY')}>Monthly</span><span style={yearlyStyles} className={styles.paymentSelector} onClick={makePeriodSwitchHandler('SELECT_YEARLY')}>Yearly</span></p>
      }
      <form
        onSubmit={submitForm}
        className={styles.signupForm}
      >
        <input
          onBlur={validateFormValue('email')}
          disabled={status === 'Disabled' ? 'disabled' : false}
          className={[(formErrors.email || ''), inputStyles, styles.email].join(' ')}
          name="email"
          defaultValue={email}
          placeholder="Email"
        />
        <div className={styles.creditCardGroup}>
          <input
            onBlur={validateFormValue('cardNumber')}
            disabled={status === 'Disabled' ? 'disabled' : false}
            className={[(formErrors.cardNumber || ''), inputStyles, styles.cardNum].join(' ')}
            name="cardNumber"
            placeholder="Card number"
          />
          <br />
          <input
            onBlur={validateFormValue('date')}
            disabled={status === 'Disabled' ? 'disabled' : false}
            className={[(formErrors.date || ''), inputStyles, styles.cardDate].join(' ')}
            name="date"
            placeholder="MM/YY"
          />
          <input
            onBlur={validateFormValue('cvc')}
            disabled={status === 'Disabled' ? 'disabled' : false}
            className={[(formErrors.cvc || ''), inputStyles, styles.cardCVC].join(' ')}
            name="cvc"
            placeholder="CVC"
          />
        </div>
        { period === 'monthly'
          && <div className={styles.priceDetail} style={{ color: textColor }}>
            {`${formattedPrice} ${currencyUC} per month (${couponInfo})`}
          </div>
        }
        { period === 'yearly'
          && <div className={styles.priceDetail} style={{ color: textColor }}>
            {`${formattedPrice} ${currencyUC} per year (${couponInfo})`}
          </div>
        }
        { period === 'semiannual'
          && <div className={styles.priceDetail} style={{ color: textColor }}>
            {`${formattedPrice} ${currencyUC} semiannually (6-months) (${couponInfo})`}
          </div>
        }
        { period === 'quarterly'
          && <div className={styles.priceDetail} style={{ color: textColor }}>
            {`${formattedPrice} ${currencyUC} quarterly (3-months) (${couponInfo})`}
          </div>
        }
        { period === 'weekly'
          && <div className={styles.priceDetail} style={{ color: textColor }}>
            {`${formattedPrice} ${currencyUC} per week (${couponInfo})`}
          </div>
        }
        { period === 'daily'
          && <div className={styles.priceDetail} style={{ color: textColor }}>
            {`${formattedPrice} ${currencyUC} per day (${couponInfo})`}
          </div>
        }
        {!subscribing
          && <button
            type="submit"
            disabled={status === 'Disabled' ? 'disabled' : false}
            className={payBtnStyles}
            onClick={submitForm(false)}
            style={{ background: buttonColor || '#469CCE', color: '#FFF' }}
            >
            {btnContent}
          </button>
        }
        { subscribing
          && <Spinner color={'#FFFFFF'} size={40} />
        }
        { (!couponsEnabled)
          && <div className={styles.unsubscribeText} style={{ color: textColor }}>
            No risk, cancel anytime
          </div>
        }
        { (couponsEnabled && !couponFieldVisible)
          && <div className={styles.unsubscribeText} style={{ color: textColor }}>
            <span tabIndex="-1" name="displayCoupon" role="button" onKeyUp={displayCouponField} onClick={displayCouponField} style={{ color: textColor || 'white', cursor: 'pointer', textDecorationLine: 'underline' }}> Have a coupon? Click to apply it.</span>
          </div>
        }
        { (couponsEnabled && couponFieldVisible)
          && <div>
            {!couponValid
              && <div
                className={styles.couponForm}
                style={{ border: `2px solid ${couponSubmitted ? 'red' : 'white'}` }}
              >
                <input
                  id="couponField"
                  className={styles.couponInputField}
                  type="text"
                  placeholder="enter coupon..."
                  defaultValue={coupon}
                  onKeyPress={handleKeyPress}
                  onFocus={(e) => { e.target.placeholder = '' } }
                  onBlur={(e) => { e.target.placeholder = 'enter coupon...' } }
                />
                <button
                  name="couponButton"
                  className={styles.couponButton}
                  style={{ borderColor: buttonColor, background: buttonColor }}
                  onClick={handleCouponClick}
                >
                  { !couponValidating && <span>apply</span> }
                  { couponValidating && <Spinner color={'#FFFFFF'} size={30} /> }
                </button>
              </div>
            }

            <div className="couponMessage" style={{ marginBottom: '10px', fontSize: '12px', color: textColor }}>
              {(couponValid && couponSubmitted)
                && <p name="couponSuccess">Coupon successfully applied!</p>
              }
              {(!couponValid && couponSubmitted)
                && <p name="invalidCoupon">Invalid coupon. Please try again.</p>
              }
            </div>
          </div>
        }
      </form>
    </div>
  )
}

export default lifecycle(methods)(StripeForm)
