import React from 'react'
import classNames from 'classnames'

import { hideModal } from '../client/subscribe/actions'

const clearInputs = () => {
  const inputs = document.getElementsByTagName('input');
  let i;
  for (i = 0; i < inputs.length; i++) {
    inputs[i].value = ''
  }
}

export const handleModal = (modalType) => (event) => {
  event.preventDefault()
  store.dispatch(hideModal(modalType))
  clearInputs()
  return false
}

const WelcomeModal = ({
  styles,
  modalProps,
  modalType,
  channel,
  group,
}) => (
  <div className={styles.WelcomeModal.bg} onClick={handleModal('close')}>
    <div className={styles.WelcomeModal.container}>
      <span className={styles.WelcomeModal.closeBtn} onClick={handleModal('close')}>X</span>
      { (!channel || channel === 'p/slack')
        && <div className={styles.WelcomeModal.header}>
          Welcome to the {group} group!
        </div>
      }
      { (channel && channel !== 'p/slack')
        && <div className={styles.WelcomeModal.header}>
          Welcome to #{channel}!
        </div>
      }
      <div className={styles.WelcomeModal.text}>
        <p name="successMessage">
          {'We\'re so excited you\'re here! Check your email for your Slack invitation and directions to get set up. Can\'t wait to talk to you soon! If you have any questions, please contact LaunchPass support at support@LaunchPass.com'}
        </p>
      </div>
      <div className={styles.WelcomeModal.buttonContainer}>
        <button
          className={styles.WelcomeModal.confirmBtn}
          onClick={handleModal('close')}>GOT IT!</button>
      </div>
    </div>
  </div>
)

export default WelcomeModal
