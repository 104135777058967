import 'whatwg-fetch'

import isEmail from 'validator/lib/isEmail'
import { fullstoryInit, fullstoryIdentify } from '../../lib/fullstory'

export const REQUEST_SUBSCRIPTION = 'REQUEST_SUBSCRIPTION'
export const RECEIVE_SUBSCRIPTION = 'RECEIVE_SUBSCRIPTION'
export const RECEIVE_SUBSCRIPTION_SCA = 'RECEIVE_SUBSCRIPTION_SCA'
export const VALIDATE_FORM = 'VALIDATE_FORM'
export const INVALIDATE_FORM = 'INVALIDATE_FORM'
export const ADDITIONAL_ERROR = 'ADDITIONAL_ERROR'
export const OPEN_MODAL = 'OPEN_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'
export const TOGGLE_COUPON_FIELD = 'TOGGLE_COUPON_FIELD'
export const COUPON_VALID = 'COUPON_VALID'
export const COUPON_INVALID = 'COUPON_INVALID'
export const COUPON_VALIDATING = 'COUPON_VALIDATING'

export const SET_STRIPE = 'SET_STRIPE'

const noop = (op) => op

export const setStripe = (stripe) => noop({
  type: SET_STRIPE,
  stripe,
})

export const openModal = (modalType) => noop({
  type: OPEN_MODAL,
  modalType,
})

export const hideModal = (modalType) => noop({
  type: HIDE_MODAL,
  modalType,
})

export const toggleCouponField = (visible) => ({
  type: TOGGLE_COUPON_FIELD,
  visible,
})

const couponValidating = (status) => ({
  type: COUPON_VALIDATING,
  status,
})

const couponInvalid = () => ({
  type: COUPON_INVALID,
})

const couponValid = (coupon) => ({
  type: COUPON_VALID,
  coupon,
})

const requestSubscription = () => noop({
  type: REQUEST_SUBSCRIPTION,
})

const receiveSubscriptionSCA = (json) => noop({
  type: RECEIVE_SUBSCRIPTION_SCA,
  results: json,
  receivedAt: Date.now(),
})
const receiveSubscription = (json) => noop({
  type: RECEIVE_SUBSCRIPTION,
  results: json.data,
  receivedAt: Date.now(),
})

const displayErrors = (errors) => noop({
  type: INVALIDATE_FORM,
  errors,
})

export const additionalError = (error) => noop({
  type: ADDITIONAL_ERROR,
  error,
})

// Subscriptions




const postSubscription = ({
  form,
  paymentOption,
  pageId,
  customRedirect,
  convertCode,
  validCoupon,
  pageCurrency
}) => async (dispatch) => {
  try {
    dispatch(requestSubscription())

    const {
      email, cardNumber, cvc, date, affiliate,
    } = form

    fullstoryInit()
    fullstoryIdentify(email, email, email)

    const stripe = new Promise((resolve, reject) => {
      Stripe.card.createToken({
        number: cardNumber,
        cvc,
        exp: date,
        currency: pageCurrency
      }, (status, response) => {
        if (response.error) {
          reject(response.error.message)
        } else {
          resolve(response.id)
        }
      })
    })

    const token = await stripe

    const data = {
      email,
      token,
      paymentOption,
      pageId,
      affiliate,
      validCoupon,
      gaClientId: window.gaClientId
    }

    const response = await fetch(`${apiUrl}/user`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })

    const results = await response.json()
    // console.log("got results", JSON.stringify(results, null, 2));


    data.stripeCustomerId = results.stripeCustomerId
  
    if (results.status === 'ok') {
      trackPurchase(results);
      if (convertCode) {
        eval(decodeURIComponent(convertCode))
        // let the code run for 5 seconds before redirect
        // await new Promise((resolve) => setTimeout(resolve, 5000));
      }
      if (results.discordInviteLink) {
        return window.location.replace(results.discordInviteLink)
      }
      if (customRedirect) {
        window.location.replace(customRedirect.startsWith('http') ? customRedirect : `http://${customRedirect}`)
      } else {
        dispatch(receiveSubscription(results))
      }
    } else {
      dispatch(additionalError(results))
    }
  } catch (error) {
    console.error(error)
    dispatch(additionalError(error))
  }
}

const handleCardAuth = async (paymentIntent) => {
  const resp = await stripe.handleCardPayment(paymentIntent.client_secret);
  if (resp.error) {
    console.error('handleCardAuth Error', resp.error.message);
  }

  return resp;
}

const handleCardSetupAuth = async (setupIntent) => {
  const resp = await stripe.handleCardSetup(setupIntent.client_secret);
  if (resp.error) {
    console.error('handleCardAuth Error', resp.error.message);
  }

  return resp;
}

const trackPurchase  = (data) => {

  console.log("trackPurchase"); //, JSON.stringify(data, null, 2))
  
  if (!data) return;
  
  if (data.domain === null || data.domain == undefined || data.domain == "") {
    console.log("ERROR track purchase with no domain")
  }
  if (data.price === null || data.price == undefined || data.price == "") {
    console.log("ERROR track purchase with no price")
  }
  //data.stripeCustomerId = results.stripeCustomerId
  if (data.paymentIntent) {
    data.tid = data.paymentIntent.id
    data.type = "one-time"
  }

  if (data.subscription) {
    data.tid = data.subscription.id
    data.type = data.period
  }

  if (data.charge) {
    data.tid = data.charge.id
    data.type = "one-time"
  }


  //console.log("need price", data);

  if (window.lp_ident)
    lp_ident(`CUSTOMER:${data.stripeCustomerId}`)

  if (window.lp_event) {
    lp_event('paidInviteSignup', {value: data.price});
    lp_event('join_group', {group_id: data.domain})


    gtag('set', 'user_properties', {
      type: 'Subscriber',
      group: data.domain,
    });

  }
}
const postSubscriptionElements = ({
  form,
  paymentOption,
  pageId,
  customRedirect,
  convertCode,
  validCoupon,
}) => async (dispatch) => {
  try {
    dispatch(requestSubscription())

    const {
      email, cardNumber, cvc, date, affiliate, card,
    } = form

    fullstoryInit()
    fullstoryIdentify(email, email, email)
    const state = store.getState()

    const data = {
      email,
      paymentOption,
      pageId,
      affiliate,
      validCoupon,
      gaClientId: window.gaClientId
    }
    const response = await fetch(`${apiUrl}/userElements`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(data),
    })
    const results = await response.json()
    //console.log('got results', JSON.stringify(results, null, 2));

    if (results.status === 'ok') {
      // console.log("confirmCardSetup");
      const result = await stripe.confirmCardSetup(results.stripePaymentIntent.client_secret, {
        payment_method: {
          card: form.card,
          billing_details: {
            email,
          },
        },
      });
        // console.log("got result", result);

      if (result.error) {
        dispatch(additionalError({ status: 'error', message: `Oops there was an error: ${result.error.message}.  If you need additional assistance please contact us at support@launchpass.com` }));
        return;
      }

      data.cardSetup = result;
      data.userId = results.userId;
      // console.log("post data", data);
      const chargeResponse = await fetch(`${apiUrl}/userChargeCard`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(data),
      });

      const chargeResponseData = await chargeResponse.json();

      // console.log('charged card', JSON.stringify(chargeResponseData, null, 2));

      if (chargeResponseData.success == false) {
        dispatch(additionalError({ status: 'error', message: `${chargeResponseData.message}` }));
        return;
      }
      // console.log('charged card', JSON.stringify(chargeResponseData, null, 2));

      if (chargeResponseData.paymentIntent) {
        // console.log('todo handle payment intent?', chargeResponseData.paymentIntent);

        if (chargeResponseData.paymentIntent.status == 'requires_action') {
          console.log('handle auth');
          const resp = await handleCardAuth(chargeResponseData.paymentIntent);
          // console.log('got resp', JSON.stringify(resp, null, 2));

          if (!resp.error) {
            // console.log('collected payment', JSON.stringify(resp, null, 2));
            trackPurchase(chargeResponseData);
            return document.location.href = `${state.page.url}/thankyou?s=2&paymentIntentId=${chargeResponseData.paymentIntent.id}`;
            
          } else {
            //console.log('got resp', JSON.stringify(resp, null, 2));
            // console.log('display error', { status: 'error', message: resp.error.message })
            dispatch(additionalError({ status: 'error', message: resp.error.message }))
          }
          return;
        } else {
          // console.log("chargeResponseData.paymentIntent.status", chargeResponseData.paymentIntent.status)
          //console.log("chargeResponseData", JSON.stringify(chargeResponseData, null, 2))
          trackPurchase(chargeResponseData)
          return document.location.href = `${state.page.url}/thankyou?s=2&paymentIntentId=${chargeResponseData.paymentIntent.id}`;

        }
        return;
      }
      if (chargeResponseData.subscription) {

        // console.log("chargeResponseData.subscription.status", chargeResponseData.subscription.status)
        if (chargeResponseData.subscription.status === 'incomplete') {
          if (chargeResponseData.subscription.latest_invoice.payment_intent) {
            // console.log('handle auth');
            const resp = await handleCardAuth(chargeResponseData.subscription.latest_invoice.payment_intent)
            if (!resp.error) {
              // console.log('collected payment', JSON.stringify(resp, null, 2));
              trackPurchase(chargeResponseData)
              return document.location.href = `${state.page.url}/thankyou?s=2&subscriptionId=${chargeResponseData.subscription.id}`;
            } else {
              console.log('display error');
              dispatch(additionalError({ status: 'error', message: resp.error.message }))
            }
            return;
          }
          
        } else if (chargeResponseData.subscription.status === 'trialing') {
          // console.log('trialing')
          if (chargeResponseData.subscription.pending_setup_intent) {
            // console.log('handle auth'); 
            const resp = await handleCardSetupAuth(chargeResponseData.subscription.pending_setup_intent);
            if (!resp.error) {
              // console.log('authenticated payment', JSON.stringify(resp));
              trackPurchase(chargeResponseData)
              document.location.href = `${state.page.url}/thankyou?s=2&subscriptionId=${chargeResponseData.subscription.id}`;
              return;
            } else {
              // console.log('display error');
              dispatch(additionalError({ status: 'error', message: resp.error.message }))

              // dispatch(additionalError(chargeResponseData));
            }
            return;
          } else {
            trackPurchase(chargeResponseData)
            document.location.href = `${state.page.url}/thankyou?s=2&subscriptionId=${chargeResponseData.subscription.id}`;
            return;

          }
          // trackPurchase(chargeResponseData)
        } else if (chargeResponseData.subscription.status === "active") {
          trackPurchase(chargeResponseData)
          document.location.href = `${state.page.url}/thankyou?s=2&subscriptionId=${chargeResponseData.subscription.id}`;
          return;
        } else {
          console.error("unhandled subscirption status", chargeResponseData.subscription.status)
          dispatch(additionalError({ status: 'error', message: "Unexpected Subscription Status" }))
        }

        return;
      }

      dispatch(additionalError({ status: 'error', message: "Unexpected Error" }))

      console.log("should never be here!!!!!", chargeResponseData);


      //error


/*      if (chargeResponseData.status == 'ok') {
        // console.log("page", state.page.url);
        if (chargeResponseData.subscription) {

          trackPurchase(chargeResponseData)
          document.location.href = `${state.page.url}/thankyou?s=2&subscriptionId=${chargeResponseData.subscription.id}`;
        } else {
          trackPurchase(chargeResponseData)
          document.location.href = `${state.page.url}/thankyou?s=2&paymentIntentId=${chargeResponseData.paymentIntent.id}`;
        }
      } else {
        dispatch(additionalError(chargeResponseData));
      }*/
    } else {
      dispatch(additionalError(results))
    }
  } catch (error) {
    console.error(error)
    dispatch(additionalError(error))
  }
}


const postSubscriptionSCA = ({
  form,
  paymentOption,
  pageId,
  customRedirect,
  convertCode,
  validCoupon,
}) => async (dispatch) => {
  try {
    dispatch(requestSubscription())

    const { email, affiliate } = form

    fullstoryInit()
    fullstoryIdentify(email, email, email)

    const data = {
      email,
      paymentOption,
      pageId,
      affiliate,
      validCoupon,
      gaClientId: window.gaClientId
    }

    const response = await fetch(`${apiUrl}/paymentIntent`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(data),
    })
    const results = await response.json()
    // console.log("got results", results);

    if (results.status === 'ok') {
      stripe.redirectToCheckout({
        sessionId: results.session.id,
      }).then((result) => {
        console.log('result then', result);
      })

      // todo check for error
    } else {
      console.log('dispatch error');
      dispatch(additionalError(results))
    }
  } catch (error) {
    console.error(error)
    dispatch(additionalError(error))
  }
}

const invalidateSubscription = (form) => {
  const errors = []

  if (!isEmail(form.email)) {
    errors.push({
      field: 'email',
      message: 'Invalid email',
    })
  }

  if (!Stripe.card.validateCardNumber(form.cardNumber)) {
    errors.push({
      field: 'cardNumber',
      message: 'Invalid card number',
    })
  }

  if (!Stripe.card.validateExpiry(form.date)) {
    errors.push({
      field: 'date',
      message: 'Invalid date',
    })
  }

  if (!Stripe.card.validateCVC(form.cvc)) {
    errors.push({
      field: 'cvc',
      message: 'Invalid CVC',
    })
  }

  return errors
}

const invalidateSubscriptionElements = (form) => {
  const errors = []

  if (!isEmail(form.email)) {
    errors.push({
      field: 'email',
      message: 'Invalid email',
    })
  }

  if (form.email != form.emailConfirm) {
    errors.push({
      field: 'emailConfirm',
      message: 'Email must match',
    })
  }

  if (!form.card._complete) {
    errors.push({
      field: 'card',
      message: 'Enter your credit card details',
    })
  }

  return errors
}

const invalidateSubscriptionSCA = (form) => {
  const errors = []

  if (!isEmail(form.email)) {
    errors.push({
      field: 'email',
      message: 'Invalid email',
    })
  }

  return errors
}

const invalidateField = (form, errors = [], field) => {
  if (form[field].length === 0) return errors

  if (field === 'email' && !isEmail(form.email)) {
    errors.push({
      field: 'email',
      message: 'Invalid email',
    })
  } else if (field === 'cardNumber' && !Stripe.card.validateCardNumber(form.cardNumber)) {
    errors.push({
      field: 'cardNumber',
      message: 'Invalid card number',
    })
  } else if (field === 'date' && !Stripe.card.validateExpiry(form.date)) {
    errors.push({
      field: 'date',
      message: 'Invalid date',
    })
  } else if (field === 'cvc' && !Stripe.card.validateCVC(form.cvc)) {
    errors.push({
      field: 'cvc',
      message: 'Invalid CVC',
    })
  } else {
    errors = errors.filter((error) => error.field !== field)
  }

  return errors
}

export const validateAndPostSubscriptionElements = (form) => (dispatch, getState) => {
  const errors = invalidateSubscriptionElements(form)
  if (errors.length) {
    return dispatch(displayErrors(errors))
  }

  const state = getState()
  const {
    pageId, alternatePayment, customRedirect, convertCode,
  } = state.page
  const yearlySelected = state.selector.yearlySelected ? 'yearly' : 'monthly'
  return dispatch(postSubscriptionElements({
    form,
    convertCode,
    paymentOption: alternatePayment ? yearlySelected : undefined,
    pageId,
    customRedirect,
    validCoupon: state.coupon.valid ? state.coupon.id : null,
  }))
}

export const validateAndPostSubscriptionSCA = (form) => (dispatch, getState) => {
  const errors = invalidateSubscriptionSCA(form)
  if (errors.length) {
    return dispatch(displayErrors(errors))
  }

  const state = getState()
  const {
    pageId, alternatePayment, customRedirect, convertCode,
  } = state.page
  const yearlySelected = state.selector.yearlySelected ? 'yearly' : 'monthly'
  return dispatch(postSubscriptionSCA({
    form,
    convertCode,
    paymentOption: alternatePayment ? yearlySelected : undefined,
    pageId,
    customRedirect,
    validCoupon: state.coupon.valid ? state.coupon.id : null,
  }))
}
export const validateAndPostSubscription = (form) => (dispatch, getState) => {
  const errors = invalidateSubscription(form)
  if (errors.length) {
    return dispatch(displayErrors(errors))
  }

  const state = getState()
  const {
    pageId, alternatePayment, customRedirect, convertCode, currency = 'usd'
  } = state.page
  const yearlySelected = state.selector.yearlySelected ? 'yearly' : 'monthly'
  return dispatch(postSubscription({
    form,
    convertCode,
    paymentOption: alternatePayment ? yearlySelected : undefined,
    pageId,
    pageCurrency: currency,
    customRedirect,
    validCoupon: state.coupon.valid ? state.coupon.id : null,
  }))
}

export const validateFormOnly = (form, field) => (dispatch, getState) => {
  const state = getState()
  if (state.form) {
    const errors = invalidateField(form, state.form.errors, field)
    dispatch(displayErrors(errors))
  } else {
    // https://app.bugsnag.com/codehouse/launchpass/errors/5efed562c456b40017c4a87a?event_id=5efed562005b7803374e0000&i=sk&m=nw
    console.log('state missing form?', state);
  }
}

// Emails

const receiveInvitation = (json) => noop({
  type: RECEIVE_INVITATION,
  results: json.data,
  receivedAt: Date.now(),
})

const invalidateEmail = (form) => (isEmail(form.email) ? [] : [{ field: 'email', message: 'Invalid email' }])

const postEmail = (form, pageId, customRedirect, convertCode) => async (dispatch) => {
  try {
    dispatch(requestSubscription())

    const { email } = form

    const data = {
      email,
      pageId,
    }

    const response = await fetch(`${apiUrl}/user`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(data),
    })

    const results = await response.json()

    if (results.status === 'ok') {

      
      if (convertCode) {
        eval(decodeURIComponent(convertCode))
        // let the code run for 5 seconds before redirect
        // await new Promise((resolve) => setTimeout(resolve, 5000));
      }
      if (results.discordInviteLink) {
        return window.location.replace(results.discordInviteLink)
      }
      if (customRedirect) {
        window.location.replace(customRedirect.startsWith('http') ? customRedirect : `http://${customRedirect}`)
      } else {
        dispatch(receiveSubscription(results))
      }
    } else {
      dispatch(additionalError(results))
    }
  } catch (error) {
    console.error(error)
    dispatch(additionalError(error))
  }
}

export const validateAndPostEmail = (form) => (dispatch, getState) => {
  const errors = invalidateEmail(form)
  if (errors.length) {
    return dispatch(displayErrors(errors))
  }

  const state = getState()
  return dispatch(postEmail(form, state.page.pageId, state.page.customRedirect, state.page.convertCode))
}

// Coupons

export const validateCoupon = (coupon) => async (dispatch, getState) => {
  try {
    const state = getState()
    dispatch(couponValidating(true))
    const response = await fetch(`${apiUrl}/coupon`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sp: state.page.pageId,
        couponCode: coupon,
      }),
    })

    const couponData = await response.json()

    if (!couponData.valid) {
      dispatch(couponInvalid())
    } else {
      dispatch(couponValid(couponData))
    }

    dispatch(couponValidating(false))
  } catch (error) {
    console.log(error)
  }
}
