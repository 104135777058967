import React from 'react'

import { hideModal } from '../client/subscribe/actions'

const clearInputs = () => {
  const inputs = document.getElementsByTagName('input');
  let i;
  for (i = 0; i < inputs.length; i += 1) {
    inputs[i].value = ''
  }
}

export const handleModal = (modalType) => (event) => {
  event.preventDefault()
  store.dispatch(hideModal(modalType))
  clearInputs()
  return false
}

const openTab = () => {
  window.open(user.discordInviteLink, '_blank')
  store.dispatch(hideModal('close'))
  clearInputs()
}

const DiscordGreeting = ({
  user,
  group,
  style,
  subscription,
  channel,
}) => (
    <div>
        { (!channel || channel === 'p/slack')
        && <div className={styles.WelcomeModal.header}>
          Welcome to the {group} discord!
        </div>
      }
      { (channel && channel !== 'p/slack')
        && <div className={styles.WelcomeModal.header}>
          Welcome to #{channel}!
        </div>
      }
      <div className={styles.WelcomeModal.text}>
      <p>Please follow these steps to join:</p>
      <p>1) Login to your Discord</p>
      <p><a className={styles.WelcomeModal.joinBtn} rel="noreferrer" target="_blank" href="http://discord.com/login">Discord Login</a></p>
      <p>2) Accept your Discord Invite</p>
      <a className={styles.WelcomeModal.joinBtn} onClick={() => openTab() } href={user.discordInviteLink} rel="noreferrer" target="_blank">Join our Discord</a>
      <p>3) Activate your account by sending the code below to the LaunchPass Bot in the server</p>
      <input className={styles.WelcomeModal.inputAccessCode} value={user.discordAccessCode}></input>
      <p>Not to worry, we’ve also emailed you these instructions.</p>
      </div>
    </div>
)

/* Welcome to our Discord server!

1) Login to your Discord
First make sure you are logged into your Discord account here: http://discord.com/login

2) Accept your Discord Invite
Follow your invite below
[Join our Discord] <-- Large button

3) Activate your account by sending the code below to the LaunchPass Bot in the server
[Copy the code] <-- Large code (copy on click)

Not to worry, we’ve also emailed you these instructions. */

const SlackGreeting = ({
  user,
  styles,
  subscription,
  channel,
}) => (
    <div>
      { (!channel || channel === 'p/slack')
        && <div className={styles.WelcomeModal.header}>
          Welcome to the {group} group!
        </div>
      }
      { (channel && channel !== 'p/slack')
        && <div className={styles.WelcomeModal.header}>
          Welcome to #{channel}!
        </div>
      }
      <div className={styles.WelcomeModal.text}>
        <p>
          {'We\'re so excited you\'re here! Check your email for your Slack invitation and directions to get set up. Can\'t wait to talk to you soon! If you have any questions, please contact LaunchPass support at support@LaunchPass.com'}
        </p>
      </div>
    </div>
)

const WelcomeModalSCA = ({
  styles,
  modalProps,
  modalType,
  channel,
  group,
  user,
  subscription,

}) => (
  <div className={styles.WelcomeModal.bg} onClick={handleModal('close')}>
    <div className={styles.WelcomeModal.container}>
        <span className={styles.WelcomeModal.closeBtn} onClick={handleModal('close')}>X</span>

      {user.discordInviteLink ? (
        <DiscordGreeting
        styles={styles}
        group={group}
        channel={channel}
        user={user}
        subscription={subscription}
        />
      ) : (
        <SlackGreeting
            styles={styles}
            group={group}
            channel={channel}
            user={user}
            subscription={subscription}
        />
      )}

      <div className={styles.WelcomeModal.buttonContainer}>
        <button
          className={styles.WelcomeModal.confirmBtn}
          onClick={handleModal('close')}>GOT IT!</button>
      </div>
    </div>
  </div>
)

export default WelcomeModalSCA
