// CardSection.js
import React from 'react';
import { CardElement } from 'react-stripe-elements';

class CardSection extends React.Component {
  render() {
    const style = {
      base: {
        color: '#333',
        fontSize: '18px',
        fontFamily: '"Source Sans Pro", sans-serif',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#333',
        },
      },
      invalid: {
        color: '#d20101',
        ':focus': {
          color: '#333',
        },
      },
    };
    const status = this.props.status;
    return (
      <span style={
        status === 'Disabled' ? { cursor: 'not-allowed' } : {}
      }>
        <div
          className={
            status === 'Disabled'
              ? `${this.props.styles.elementsDisabledContainer}`
              : `${this.props.styles.elementsContainer}`
          }
        >
          <CardElement onBlur={this.props.onBlur} style={style} />
        </div>
      </span>
    );
  }
}

export default CardSection;
